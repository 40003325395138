

import { defineComponent, onMounted, reactive, toRefs, ref, watch } from "vue";
import PlacaModal from "@/components/modals/geral/PlacaModal.vue";
import LogEtapasModal from "@/components/modals/OficinaDigitalModals/LogEtapasModal.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import MessageModal from "@/components/modals/geral/MessageModal.vue";
import ApexChart from "apexcharts";
import moment from 'moment';
import { setOrcamentoNegociacao } from "@/services/OficinaDigital";
import useAlert from "@/composables/Alert";
import store from "@/store/index";


export default defineComponent({
	name: "ConversaodePacotesCard",
	components: {
		PlacaModal,
		MessageModal,
		LogEtapasModal
	},
	props: {
		id: Number,
		dataOrcamento: Array,
		dadosChecklist: Object,
	},

  	setup(props){
		const { showConfirmAlert, showTimeAlert } = useAlert();
		const dominio_orcamento = store.getters.layoutConfig("distribuidor.dominio_orcamento");
		// const dominio_orcamento = "dev.orcamento.app";

		const dominio_checklist = store.getters.layoutConfig("distribuidor.dominio_checklist");
		// const dominio_checklist = "checklist.desenvolvimento.acampa.app";

		let orcamentoSelected = ref({});
	   	let dados:any = reactive({		   
			itensAprovados: [],
			itensReprovados: [],
			servicos: [],
			info: {
				placa: "",
				cliente: "",
				modelo: "",
				email: "",
				telefone: "",
				consultor: "",
				mecanico: "",
				dataReprovacao: "",
        	},
	   	});

		async function editaOrcamento(orcamentoSelected, servicoSelected){
			Swal.fire({
				title: "Deseja confirmar a aprovação do serviço?",
				text: "A ação não poderá ser desfeita",
				position: 'center',
				showConfirmButton: true,
				reverseButtons: true,
				allowOutsideClick: false,
				showCancelButton: true,
				customClass: {
					confirmButton: "btn btn-flex background_btn_1_opct text-white",
					cancelButton: "btn btn-light me-3",
				},
				confirmButtonText: "Sim",
				cancelButtonText: "Não",
				focusConfirm: false,
			}).then((result) => {
				if (result.isConfirmed) {
					const postData = {
						codOrcamento: servicoSelected.codOrcamento,
						codConcessionaria: orcamentoSelected.codConcessionaria,
						checklist: orcamentoSelected.checklist, 
						codCheckList: orcamentoSelected.codCheckList,
						aprovacao: 1
					};

					setOrcamentoNegociacao(postData)
					.then(response => {
						showTimeAlert("O serviço foi aprovado com sucesso!");
					})
					.catch(() => {
						showTimeAlert("Não foi possível completar a solicitação", "error");
						servicoSelected.situacaoOnOff = false;
					});
				} else {
					servicoSelected.situacaoOnOff = false;
				}
			});
		};

		function getAtualizacao(cliente){
			orcamentoSelected.value = Object.assign({}, cliente);
		   	dados.servicos = [...cliente.servicos];

			let aux:any = dados.servicos.filter((item, index) => {
				if(item['situacao']=='aprovado'){
					return item;
				};
			});

			dados.itensAprovados = aux;	

			let aux2:any = dados.servicos.filter((item, index) => {
				if(item['situacao']=='reprovado'){
					return item;
				};
			});

			dados.itensReprovados = aux2;
		};

		function getInformacoes(cliente){
			orcamentoSelected.value = Object.assign({}, cliente);

			dados.info.placa = cliente.placa;
			dados.info.cliente = cliente.cliente;
			dados.info.modelo = cliente.modelo;
			dados.info.email = cliente.email;
			dados.info.telefone = cliente.telefone;
			dados.info.consultor = cliente.nomeConsultor;
			dados.info.mecanico = cliente.nomeMecanico;
			dados.info.dataReprovacao = cliente.e_data + " " + cliente.e_hora;
		};

		function trataNome(nome){
			if(!nome || nome == "undefined" || nome == undefined){
				return "";
			}
			const arrayNomes = nome.split(" ");

			let nomeTratado = `${arrayNomes[0]}`;
			if(arrayNomes[1]){
				nomeTratado += ` ${arrayNomes[1].slice(0, 0)}.`;
			}

			return nomeTratado;
    	};	

		return {
			...toRefs(dados),
			trataNome,
			getAtualizacao,
			getInformacoes,
			editaOrcamento,
			orcamentoSelected,
			dominio_orcamento,
			dominio_checklist
	  	};
  	},
});

